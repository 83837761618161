import { resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid grid-nogutter" }
const _hoisted_2 = { class: "col-9 col-offset-2 mt-8" }
const _hoisted_3 = { class: "formgrid grid grid-nogutter" }
const _hoisted_4 = { class: "field col-8 pr-2" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  id: "barcode-help",
  class: "p-error"
}
const _hoisted_7 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_InputText, {
                ref: "scan",
                type: "text",
                "aria-describedby": "barcode-help",
                placeholder: `Scan ${this.item}`,
                class: _normalizeClass({
                  'p-invalid': _ctx.v$.barcode.required.$invalid,
                  'w-full': true,
                  'h-3rem': true,
                }),
                modelValue: _ctx.barcode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.barcode) = $event)),
                onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.fetchdata()), ["enter"])),
                autofocus: ""
              }, null, 8, ["placeholder", "class", "modelValue"]),
              (_ctx.v$.barcode.required.$invalid)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.v$.barcode.required.$message), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Button, {
                label: "Next",
                type: "submit",
                loading: this.loading,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchdata())),
                class: "h-3rem"
              }, null, 8, ["loading"])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}